@font-face {
  font-family: 'Roundo';
  src: local('Roundo-Bold'), url('./fonts/Roundo/Roundo-Regular.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Roundo';
  src: local('Roundo-Bold'), url('./fonts/Roundo/Roundo-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Clash';
  src: local('Clash'), url('./fonts/Clash/ClashDisplay-Regular.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Clash';
  src: local('Clash-Bold'), url('./fonts/Clash/ClashDisplay-Bold.otf');
  font-weight: 700;
}
